/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
/* Tippy */
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/dist/border.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

:root {
    // Insite shared imports
    @import '../../../../libs/insite-teams/shared/src/lib/styles/badges.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/utils.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/placeholders.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/lists.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/responsive-utils.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/fab.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/tabs.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/datepicker.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/buttons.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/cards.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/action-sheet.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/misc.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/outer-content.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/toolbars.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/forms.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/popovers.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/user-lists.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/alert-header.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/dropdowns.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/help-widget.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/avatars.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/clickable-elements.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/alerts.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/tags.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/loading-spinners.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/profile.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/tippy.scss';
    @import '../../../../libs/insite-teams/shared/src/lib/styles/shared-modals.scss';

    // Admin variables for shared pages
    @import '../src/theme/auth-admin';

    // Admin only theme files
    @import '../src/theme/admin-global';
    @import '../src/theme/admin-modals';
}
