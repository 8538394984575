ion-badge {
    border-radius: 999px;
    border: 1px solid var(--ion-color-base);
    &:not(.normal-padding) {
        --padding-start: 8px;
        --padding-end: 8px;
    }

    &.badge-padding-6 {
        --padding-start: 6px;
        --padding-end: 6px;
    }

    &.height-20 {
        height: 20px;
    }

    &.height-24 {
        height: 24px;
    }
}

ion-badge.user-role-badge {
    min-width: 76px;
    height: 28px;
}

.read-only-badge {
    margin-bottom: 8px;
    --padding-start: 5px;
    --padding-end: 5px;
    border: 1px solid var(--ion-color-contrast);
    font-weight: 500;
}

.md {
    .read-only-badge {
        width: 77px;
    }
}

.ios {
    .read-only-badge {
        margin-bottom: 6px;
    }
}

.badge-bordered-dark {
    border: 1px solid var(--ion-color-dark);
}

.badge-bordered-medium {
    border: 1px solid var(--ion-color-medium);
}

.badge-bordered-shade {
    border: 1px solid var(--ion-color-shade);
}

.badge-bordered-contrast {
    border: 1px solid var(--ion-color-contrast);
}

.badge-bordered-primary {
    border: 1px solid var(--ion-color-primary);
}

.badge-height-container {
    min-height: 24px;
}
