// Header
ion-modal {
    .admin-modal-header {
        padding-top: 4px;
        min-height: 60px;

        ion-title {
            padding-right: 0;
        }
    }

    .modal-content::-webkit-scrollbar {
        display: none;
    }

    ion-backdrop {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
}

.modal-close-button {
    z-index: 1002;
    position: absolute;
    right: 0;
    top: 6px;
    --padding-start: 6px;
    --padding-end: 6px;

    ion-icon {
        font-size: 24px;
    }
}

@media (min-width: 576px) {
    ion-modal {
        .admin-modal-header {
            ion-title {
                margin-left: 16px;
            }

            ion-buttons[slot='start'] {
                margin: 0 0 0 18px;
            }

            ion-buttons[slot='end'] {
                margin-right: 16px;
            }
        }
    }
}

// Never fullscreen initial
.add-or-invite-project-users-modal {
    --width: 94%;
    --max-width: 620px;
    --height: 600px;
    --max-height: 95%;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            height: 100%;
            position: relative;
            contain: content;
            max-width: 98vw;
        }
    }
}

.payment-modal {
    --height: auto;
    --width: 94%;
    --max-width: 538px;
    --border-radius: 18px !important;

    .modal-wrapper {
        position: relative;

        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 98vw;
        }
    }
}

.upgrade-plan-modal {
    --height: auto;
    --width: auto;
    --max-width: 370px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}

.welcome-modal,
.enterprise-enquiry-modal {
    --height: unset;
    --min-height: 90%;
    --width: 90%;
    --border-radius: 18px !important;

    .modal-wrapper {
        position: relative;
    }
}

// Always responsive height
.manage-member-modal {
    --height: auto;
    --width: 90%;
    --max-width: 600px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 98vw;
        }
    }
}

.change-plan-modal {
    --height: 100%;
    --max-height: unset;
    --width: 100%;
    --max-width: unset;
    --border-radius: 0 !important;

    .modal-wrapper {
        justify-content: center;
        display: flex;

        .ion-page {
            position: relative;
            contain: content;
            max-height: 100vh;
            max-width: 100vw;
            width: 100%;
            justify-content: center;

            .modal-content {
                width: max-content;
                margin: auto;
                max-width: 100vw;
            }
        }
    }
}

.transaction-details-modal {
    --height: auto;
    --width: 90%;
    --max-width: 800px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}

.add-internal-collaborator-modal {
    --height: auto;
    --width: 90%;
    --max-width: 600px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}

.trail-learn-more-modal {
    --height: auto;
    --width: 90%;
    --max-width: 600px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}

.users-to-invite-modal {
    --height: auto;
    --width: 90%;
    --max-width: 600px;
    --border-radius: 18px !important;

    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }

    ion-backdrop {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
}

// Now make responsive
@media (min-height: 600px) {
    .welcome-modal {
        --min-height: 530px;
    }
}

@media (min-height: 700px) {
    .enterprise-enquiry-modal {
        --min-height: 676px;
    }
}

@media (min-width: 576px) {
    .welcome-modal {
        --max-width: 600px;
    }
    .enterprise-enquiry-modal {
        --max-width: 500px;
    }
    .upgrade-plan-modal {
        --height: auto;
        --max-width: 720px;

        .modal-wrapper {
            .ion-page {
                position: relative;
                contain: content;
                max-height: 90vh;
                max-width: 90vw;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .upgrade-plan-modal {
        --width: 518px;
    }
}

@media (min-width: 768px) {
    .payment-modal {
        --max-width: 525px;
    }
    .enterprise-enquiry-modal {
        --max-width: 720px;
    }
    .upgrade-plan-modal {
        // Make modal responsive width
        --width: auto;

        app-plan-overview {
            min-width: 240px;

            .plan-card {
                max-width: 270px;

                &:not(.is-selected) {
                    height: 100%;
                }
            }

            &.card-height-100 {
                .plan-card {
                    height: 100%;
                }
            }
        }
    }

    .change-plan-modal {
        app-plan-overview {
            .plan-card {
                height: 100%;
                max-width: 340px;
            }
        }

        ion-col {
            &:nth-child(odd) .plan-card {
                margin-left: auto;
            }

            &:nth-child(even) .plan-card {
                margin-right: auto;
            }
        }
    }
}

@media (min-width: 992px) {
    .payment-modal {
        --max-width: 778px;
    }
}

// Fullscreen mobile only
.default-terms-modal {
    .modal-wrapper {
        .ion-page {
            justify-content: flex-start;
        }
    }
}

@media (min-width: 576px) and (min-height: 500px) {
    .modal-fullscreen-mobile-only {
        --max-width: 90vw;
        --width: 600px;
        --border-radius: 18px;
    }
    .add-member-modal {
        --height: auto;

        .modal-wrapper {
            .ion-page {
                position: relative;
                contain: content;
                max-height: 90vh;
                max-width: 90vw;
            }
        }
    }
    .manage-report-profile-modal,
    .default-terms-modal {
        --height: auto;
        --max-width: 90vw;
        --width: 700px;
        --border-radius: 18px !important;

        .modal-wrapper {
            .ion-page {
                position: relative;
                contain: content;
                max-height: 90vh;
                max-width: 90vw;
            }
        }
    }
    .change-limit-modal {
        --height: 434px !important;
        --max-width: 400px;
    }
}
