.user-row {
    border-radius: 14px;
    padding: 0 6px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover:not(.is-current-user):not(.user-row-disabled) {
        border-color: var(--ion-color-primary);
    }

    &:active:not(.is-current-user):not(.no-shrink):not(.user-row-disabled) {
        transform: scale(0.99);
        background: var(--ion-color-light-blue-bg);
    }

    &.user-row-disabled {
        cursor: default;
    }

    &.is-current-user {
        background: var(--ion-color-light-blue-bg);
        &:not(.always-clickable) {
            cursor: default;
        }
        &.always-clickable:hover:not(.user-row-disabled) {
            border-color: var(--ion-color-primary);
        }
        &.always-clickable:active:not(.user-row-disabled) {
            transform: scale(0.99);
            background: var(--ion-color-light-blue-bg);
        }
    }
}

ion-avatar.user-row-avatar {
    display: flex;
    width: 42px;
    min-width: 42px;
    height: 42px;
    line-height: 18px;
    background: var(--ion-color-tertiary);
}

@media (max-width: 575px) {
    .user-row-info {
        margin-left: 12px;
        padding-right: 4px;
        max-width: calc(100% - 54px);
    }
}

@media (min-width: 576px) {
    ion-avatar.user-row-avatar {
        width: 48px;
        height: 48px;
    }
    .user-row-info {
        margin-left: 24px;
    }
}
