body {
    --ion-color-auth-primary: var(--ion-color-white);
    --ion-color-auth-secondary: #f4f4f4;
    --ion-color-auth-text: var(--ion-color-admin-dark);
    --ion-color-auth-border: var(--ion-color-medium);
    --ion-color-auth-background-tablet: var(--ion-color-white);
}

.billing-interval-buttons {
    background: #fff;
    width: max-content;
    margin: 0 auto;
    padding: 0 2px;
    border-radius: 9999px;
    border: 1px solid var(--ion-color-light-med);

    ion-button {
        --border-radius: 9999px !important;
        --ripple-color: transparent;
    }
}

.auth-logo {
    margin: 16px auto 18px;
    img {
        height: 42px;
    }
    .auth-logo-text {
        border-left: 2px solid var(--ion-color-admin-dark);
        height: 100%;
        color: var(--ion-color-admin-dark);
    }
}
