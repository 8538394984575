// RANGE
ion-range {
    --height: 28px;
}

// ICONS
ion-icon[name='ios-log-out'],
ion-icon[name='md-git-network'],
ion-icon[name='md-download'] {
    transform: rotate(180deg);
}

ion-icon[name='options'].rotate-options-icon {
    transform: rotate(90deg);
    width: 22px;
}

// MODAL
@media (min-width: 768px) and (min-height: 600px) {
    ion-modal {
        --border-radius: 18px;
    }
}

// LOADING SPINNERS
.load-spinner-container {
    height: 400px;
    max-height: 30vh;
    display: flex !important;
    align-items: flex-end !important;
    .load-spinner {
        top: 0;
    }
}

.load-spinner-container-200 {
    height: 200px;
    display: flex !important;
    align-items: center !important;
    .load-spinner {
        top: 0;
    }
}

.load-spinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
    width: 60px !important;
    height: 60px !important;
}

.load-spinner-sm {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30px !important;
    height: 30px !important;
}

// ALERT
.alert-wrapper {
    min-width: 300px !important;
}
@media (min-width: 360px) {
    .alert-w-360 {
        .alert-wrapper {
            min-width: 360px !important;
        }
    }
}

// TOAST
ion-toast {
    --background: #f6fafd;
    --color: var(--ion-color-admin-dark);
    --border-radius: 10px;
    --border-color: var(--ion-color-primary);
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: none;
}

// ROW HEADER
.teams-row-header {
    align-items: flex-end !important;
    margin-left: 28px;
    margin-right: 26px;
    overflow: hidden;
    flex-wrap: nowrap;

    ion-text {
        overflow: hidden;
        h5,
        h6 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.row-header-button {
    --padding-start: 6px;
    --padding-end: 6px;
    margin-bottom: 2px;
    height: 34px !important;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
}

.ios {
    .row-header-button {
        font-size: 16px;
        margin-bottom: 3px;
    }
}

// TABLE HEADER
.teams-table-header-container {
    margin-left: 28px;
    margin-right: 26px;
    border-bottom: 1px solid #c8c7cc;
}

.teams-table-header-row {
    margin: 0 -10px 0 -12px;
}

// SLIDES
.view-image-modal-bullets {
    .swiper-pagination-bullets {
        bottom: calc(4px + var(--ion-safe-area-bottom, 0px)) !important;
    }

    .swiper-pagination-bullet {
        opacity: 0.8;
    }
}

.vertical-align-slide-content {
    .swiper-wrapper {
        align-items: center;
    }
}
