ion-tab-bar {
    height: 40px;
    border: none;

    ion-label {
        width: 100%;
        padding-bottom: 6px;
    }

    ion-tab-button.tab-selected {
        ion-label {
            border-bottom: 1px solid #ffffff;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tablet-tab-title {
        display: flex;
        flex-grow: 1;
        width: 30%;
        font-size: 18px;

        ion-title {
            text-align: start;
        }
    }

    ion-tab-bar {
        padding-top: var(--ion-safe-area-top);

        ion-label {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    .md {
        ion-tab-bar {
            height: 56px;
        }
    }

    .ios {
        ion-tab-bar {
            height: 44px;

            ion-title {
                padding-left: 60px;
                padding-right: 20px;
                text-align: center;
            }

            ion-tab-button {
                max-width: 150px;
            }
        }
    }
}

@media (min-width: 992px) {
    ion-tab-bar {
        height: 33px;
        padding-top: 16px;
        padding-bottom: 4px;

        ion-text.page-title {
            padding-left: 28px;
        }

        ion-tab-button {
            --ripple-color: transparent;
            --padding-end: 0;
            --padding-start: 0;
            justify-content: flex-end;
            flex: unset;
            margin-top: -10px;

            ion-label {
                width: 100%;
                padding: 0 20px 6px;
                border-bottom: 1px solid var(--ion-color-med-dark);
                margin: 0;
            }

            &:last-child {
                margin-right: 16px;
            }

            &.tab-selected {
                border-bottom: 2px solid var(--ion-color-primary);
                margin-bottom: -1px;

                &.ios {
                    margin-bottom: -2px;
                }

                ion-label {
                    border-bottom: 1px solid var(--ion-color-primary);
                    color: var(--ion-color-primary);
                    padding-bottom: 5px;
                }
            }
        }
    }
}

@media (min-width: 1086px) {
    ion-tab-bar {
        padding-top: 26px;
        padding-left: calc(4% - 16px);
        padding-right: calc(4% - 16px);
    }
}

@media (min-width: 1584px) {
    ion-tab-bar {
        align-self: center;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        max-width: 1500px;

        ion-text {
            margin-left: 50px;
            padding-left: 0;
        }

        ion-tab-button {
            &:last-child {
                margin-right: 66px;
            }
        }
    }
}
