.profile-image {
    height: 100px;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    flex-grow: 1;
    border-radius: 50%;
    overflow: hidden;
    z-index: 999;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
    &:not(.no-border) {
        border: 3px solid #ffffff;
    }

    img {
        min-height: 100%;
        object-fit: cover;
    }
}
