// Standard custom popover styles
.custom-popover {
    --width: auto;
    --max-width: 250px;
    --min-width: 170px;

    .popover-content {
        border-radius: 14px;
        border: 1px solid var(--ion-color-light-med);
    }

    &.ios {
        .popover-arrow {
            display: none;
        }

        .popover-content {
            margin-top: 2px;
        }
    }

    &.md {
        --backdrop-opacity: 0.08;
    }
}

.popover-list {
    ion-list-header {
        --color: var(--ion-color-primary);
        padding-right: 16px;
    }

    ion-item {
        --min-height: 42px;
    }

    margin: 0;
}

.item-height-48 {
    .popover-list ion-item {
        --min-height: 48px;
    }
}

// Bespoke popovers
.user-groups-popover {
    .popover-content {
        margin-left: 75px;
    }
}

.user-profile-popover {
    --width: 220px;
}

.table-input-popover {
    --width: 280px;
    --max-width: unset;
}

.report-theme-colour-popover {
    --width: 320px;
    --max-width: unset;
}

.manage-status-option-popover {
    --width: 277px;
    --max-width: unset;
}

.list-section-actions-popover {
    --max-width: unset;
}
