ion-fab {
    padding-bottom: 20px;
}

ion-fab-button {
    --box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

    ion-icon {
        pointer-events: none;
    }
}

ion-fab-list {
    margin-bottom: 86px;

    &.fab-list-side-start {
        ion-button.fab-label-btn {
            display: none;
        }
    }
}

.fab-pad {
    height: calc(90px + var(--ion-safe-area-bottom, 0));
}

.fab-label-btn {
    position: absolute;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
    right: 60px;
    width: 90px;
    border-radius: 999px;

    button {
        color: var(--ion-color-dark);
    }
}

.fab-label-1 {
    bottom: 8.5px;
}

.fab-label-2 {
    bottom: 58.5px;
}

.fab-label-3 {
    bottom: 108.5px;
}

.fab-placeholder-arrow {
    position: absolute;
    height: 100px;
    right: 62px;
    bottom: 35px;

    &.fixed {
        position: fixed;
    }
}

@media (min-width: 1086px) {
    ion-fab {
        right: 4%;
    }

    .fab-placeholder-arrow {
        right: calc(4% + 62px);
    }
}
