.white-crescent {
    --background: transparent;
    --spinner-color: var(--ion-color-white);
    .loading-wrapper {
        box-shadow: none;
    }
}

.loading-wrapper {
    border-radius: 14px;
}
