ion-avatar.initials-avatar {
    border-radius: 50%;
    font-size: 15px;
    text-transform: uppercase;

    &.size-44,
    &.size-44-60,
    &.size-44-48 {
        height: 44px;
        width: 44px;
        font-size: 18px;
        margin-right: 12px;

        ion-icon {
            font-size: 22px;
            width: 44px;
            pointer-events: none;
        }
    }

    &.size-60 {
        height: 60px;
        width: 60px;
        font-size: 18px;
        margin-right: 12px;

        ion-icon {
            font-size: 30px;
            width: 54px;
        }
    }
}

ion-avatar.initials-avatar.avatar-light {
    background: var(--ion-color-light);
}

ion-avatar.initials-avatar.avatar-primary-tint {
    background: var(--ion-color-primary-tint);
    &.hoverable:hover {
        background: #45abe2;
    }
}

@media (min-width: 576px) {
    ion-avatar.initials-avatar.size-44-48,
    ion-avatar.initials-avatar.size-44-60 {
        margin-right: 20px;
    }
}

@media (min-width: 768px) {
    ion-avatar.initials-avatar {
        &.size-44-48 {
            height: 48px;
            width: 48px;
            font-size: 18px;

            ion-icon {
                font-size: 24px;
            }
        }

        &.size-44-60 {
            height: 60px;
            width: 60px;
            font-size: 22px;

            ion-icon {
                font-size: 32px;
                width: 60px;
            }
        }
    }
}
