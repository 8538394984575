ion-action-sheet {
    --color: var(--ion-color-dark);
}
.action-sheet-container {
    padding: 0 !important;
    .action-sheet-group {
        --background: var(--ion-color-white);
        margin-bottom: -50px !important;
        padding-bottom: calc(65px + var(--ion-safe-area-bottom)) !important;
        border-radius: 16px 16px 0 0;
        .action-sheet-button:first-of-type {
            pointer-events: all !important;
            background-image: url('../../../assets/imgs/custom-icons/close-medium.svg');
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: calc(100% - 16px);
            background-size: 26px;
        }
        .action-sheet-button {
            text-align: left;
            --button-background-hover: var(--ion-color-medium);
            --button-background-hover-opacity: 0.2;
            color: var(--ion-color-dark);
            outline: 2px solid var(--ion-color-white);
            ion-icon.sc-ion-action-sheet-md {
                margin-inline-end: 14px !important;
                padding-bottom: 0;
            }
            ion-icon[aria-label='options'] {
                transform: rotate(90deg);
                width: 24px;
            }
            &.action-sheet-button-selected {
                color: var(--ion-color-primary);
                span.action-sheet-button-inner::after {
                    height: 15px;
                    width: 7px;
                    display: inline-block;
                    transform: translateY(-50%) rotate(45deg);
                    border-bottom: 2px solid var(--ion-color-primary);
                    border-right: 2px solid var(--ion-color-primary);
                    content: '';
                    position: absolute;
                    right: 8px;
                    top: calc(50% - 3px);
                }
            }
            &.action-sheet-button-selected ion-icon {
                color: var(--ion-color-primary);
            }
        }
    }
}

.action-sheet-intermittent-header {
    pointer-events: none;
    font-size: 18px;
    font-weight: 500;
    color: var(--ion-color-admin-dark);
    padding-left: 16px;
}
