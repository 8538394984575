body {
    & > iframe.paddle-frame {
        width: 100vw !important;
    }
}

::-webkit-scrollbar {
    display: none;
}

.admin-desktop-padding {
    padding: 0 5% 16px 5%;
}

.numeric-info {
    display: flex;
    align-items: baseline;
}

.placeholder {
    margin-top: 48px;
}

app-admin-header {
    z-index: 1000;
}

app-get-started {
    app-plan-overview {
        .plan-card {
            min-width: 100%;
        }
    }
}

@media (max-width: 575px) {
    .numeric-info {
        ion-text:first-child {
            margin: 14px 24px 14px 4px;
        }

        &.is-bordered {
            border-bottom: 1px solid var(--ion-color-light-med);
        }
    }
}
@media (min-width: 576px) {
    .numeric-info {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90px;
        &.is-bordered {
            border-right: 1px solid var(--ion-color-light-med);
        }
    }
    .placeholder {
        margin-top: 72px;
    }
}

@media (max-width: 872px) {
    .admin-desktop-padding {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1086px) {
    .admin-desktop-padding {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 1584px) {
    .admin-desktop-padding {
        padding-left: 66px;
        padding-right: 66px;
        max-width: 1500px;
        margin: auto;
    }
}

// Auth pages
app-auth-slides.ion-page,
app-verification.ion-page,
app-auth-checkout.ion-page {
    display: table !important;
    position: relative !important;
}
