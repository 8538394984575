ion-header,
ion-tab-bar {
    ion-toolbar {
        --border-width: 0 !important;
        ion-title {
            letter-spacing: 0.3px;
            font-weight: 500;
            &.md {
                padding-right: 10px;
                font-size: 18px;
            }
            &.ios {
                padding: 0 78px;
            }
        }
        ion-icon {
            font-size: 24px !important;
        }

        ion-buttons[slot='end'].ios {
            ion-button {
                max-width: 70px;
                white-space: normal;
                --border-radius: 0 !important;
            }
        }
    }
}
@media (min-width: 576px) {
    ion-header,
    ion-tab-bar {
        ion-toolbar {
            ion-title {
                font-size: 20px;
            }
        }
    }
}
.ios {
    ion-back-button.back-button-has-icon-only {
        --icon-padding-start: 5px;
        --icon-padding-end: 16px;
    }
}

ion-toolbar {
    --background: #f9f9f9;
}

ion-toolbar.settings-page-header {
    --min-height: 45px;
    ion-title {
        text-align: left;
        font-size: 16px !important;
        padding: 0 20px !important;
        margin-top: 2px;
        --color: var(--ion-color-admin-dark);
    }
}

.footer-title {
    padding-inline-start: 50px;
    padding-inline-end: 50px;
    font-size: 12px;
    font-weight: 500;
}

.sticky-header {
    position: sticky;
    z-index: 1000;
    top: 0;
    &.top-neg4 {
        top: -4px;
    }
}

// SEARCHBAR
ion-searchbar.toolbar-searchbar,
.inline-searchbar {
    --border-radius: 999px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    .searchbar-input {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    ion-icon {
        top: 0 !important;
    }
}

// TOOLBAR SEARCHBAR
ion-searchbar.toolbar-searchbar {
    height: 34px;
    padding-left: 0;
    padding-right: 0;

    &.ion-color-secondary {
        caret-color: var(--ion-color-white);
    }

    .searchbar-input {
        height: 34px;
    }
    ion-icon,
    .searchbar-clear-button {
        height: 34px !important;
    }
}

.search-toolbar-btn {
    height: 34px;
    min-width: 34px;
    --background-activated: #008cd9;
    --background-activated-opacity: 0;
}

// INLINE SEARCHBAR
ion-searchbar.inline-searchbar {
    border-radius: 999px;
    height: 36px;
    padding-left: 0;
    padding-right: 0;
    --placeholder-opacity: 0.8;
    &.inline-search-medium {
        border: 1px solid var(--ion-color-medium);
    }
    &.inline-search-light-med {
        border: 1px solid var(--ion-color-light-med);
    }
    .searchbar-input {
        height: 34px;
        font-size: 15px;
        padding-right: 28px;
        padding-inline-start: 45px;
    }
    ion-icon,
    .searchbar-clear-button {
        height: 34px !important;
        right: 6px;
    }
    &.searchbar-has-focus {
        border: 2px solid var(--ion-color-primary);
        --placeholder-opacity: 0.5;
        .searchbar-input {
            height: 32px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-inline-start: 44px;
        }
        ion-icon,
        .searchbar-clear-button {
            height: 32px !important;
        }
        .searchbar-search-icon {
            left: 15px;
        }
    }
}

// UNDERLINED SEARCHBAR
ion-searchbar.underlined-searchbar {
    height: 36px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-style: solid;
    border-width: 0 0 1px 0;
    --placeholder-opacity: 0.8;
    .searchbar-input {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 34px;
        font-size: 15px;
        padding-right: 20px;
        padding-left: 30px;
    }

    .searchbar-clear-button {
        right: 0;
    }

    .searchbar-search-icon {
        height: 34px !important;
        top: 0 !important;
        left: 0;
        opacity: 0.8;
    }

    &.underlined-search-medium {
        border-color: var(--ion-color-medium);
    }
    &.underlined-search-light-med {
        border-color: var(--ion-color-light-med);
    }

    &.searchbar-has-focus {
        border-color: var(--ion-color-primary);
        --placeholder-opacity: 0.5;
        .searchbar-input {
            height: 32px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-inline-start: 30px;
        }
        ion-icon,
        .searchbar-clear-button {
            height: 32px !important;
        }
        .searchbar-search-icon {
            opacity: 1;
        }
    }
}
