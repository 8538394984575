.alert-header {
    border-radius: 14px;

    &.info-alert {
        border: 1px solid var(--ion-color-primary-tint);
        background: var(--ion-color-tertiary-tint);
    }

    &.action-required {
        border: 1px solid var(--ion-color-warning-shade);
        background: var(--ion-color-warning);
    }
}

.alert-icon {
    font-size: 42px;
    margin-right: 6px;
}

.minimise-button {
    margin: -2px -12px 0;
}

@media (max-width: 575px) {
    .alert-header {
        padding: 8px 16px 8px 16px;
    }
    .alert-action-button {
        justify-content: flex-end;

        ion-button {
            margin: 0;
        }
    }
    .message-text {
        margin-bottom: 4px;
    }
}

@media (max-width: 767px) {
    .alert-header {
        margin-bottom: 16px;
    }
}

@media (min-width: 576px) {
    .flex-row-small {
        flex-direction: row;
    }
    .alert-icon {
        font-size: 50px;
        margin: auto 16px;
    }
    .alert-action-button {
        margin: 0 16px;
    }
    .minimise-button {
        margin: 6px 4px;
    }
}
