@mixin responsive-modal {
    --height: auto;
    --width: 90%;
    --border-radius: 18px !important;
    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}

// Fixed custom width
.manage-project-user-modal {
    @include responsive-modal;
    --max-width: 420px;
}

.select-default-item-access-modal {
    --height: auto;
    --width: 90%;
    --border-radius: 18px !important;
    .modal-wrapper {
        .ion-page {
            position: relative;
            contain: content;
            max-height: 90vh;
            max-width: 90vw;
        }
    }
    --max-width: 440px;
}

ion-modal {
    .admin-modal-header {
        padding-top: 4px;
        min-height: 60px;
        ion-title {
            padding-right: 0;
        }
    }
}

@media (min-width: 576px) {
    ion-modal {
        .admin-modal-header {
            ion-title {
                margin-left: 16px;
            }
            ion-buttons[slot='start'] {
                margin: 0 0 0 18px;
            }
            ion-buttons[slot='end'] {
                margin-right: 16px;
            }
        }
    }

    .terms-modal {
        --width: 90vw;
        --height: 90vh;
        --border-radius: 18px;
    }
}
