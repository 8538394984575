// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
//@import "../theme/fonts";
/** Ionic CSS Variables **/

$customColors: (
    'white',
    'excel',
    'pdf',
    'complete',
    'incomplete',
    'purple',
    'med-dark',
    'light-med',
    'outer-content',
    'auth-primary',
    'auth-secondary',
    'auth-text',
    'auth-border',
    'admin-dark',
    'light-blue-bg',
    'warning-dark',
    'mint',
    'pdf-badge',
    'xlsx-badge',
    'priority-high',
    'priority-medium',
    'priority-low',
    'external-role',
    'internal-role',
    'light-blue',
    'f9'
);
@each $color in $customColors {
    .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
    }
}
:root {
    // COLORS
    /** primary **/
    --ion-color-primary: #008cd9;
    --ion-color-primary-rgb: 0, 140, 217;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #007bbf;
    --ion-color-primary-tint: #55b2e5;

    /** secondary **/
    --ion-color-secondary: #0076b9;
    --ion-color-secondary-rgb: 0, 118, 185;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0068a3;
    --ion-color-secondary-tint: #1a84c0;

    /** tertiary **/
    --ion-color-tertiary: #bfe2f6;
    --ion-color-tertiary-rgb: 191, 226, 246;
    --ion-color-tertiary-contrast: #008cd9;
    --ion-color-tertiary-contrast-rgb: 56, 128, 255;
    --ion-color-tertiary-shade: #a8c7d8;
    --ion-color-tertiary-tint: #c3ecfd;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffefb5;
    --ion-color-warning-rgb: 255, 239, 181;
    --ion-color-warning-contrast: #262626;
    --ion-color-warning-contrast-rgb: 38, 38, 38;
    --ion-color-warning-shade: #ffd953;
    --ion-color-warning-tint: #fff1bc;

    /** warning-dark **/
    --ion-color-warning-dark: #c88c17;
    --ion-color-warning-dark-rgb: 200, 140, 23;
    --ion-color-warning-dark-contrast: #ffffff;
    --ion-color-warning-dark-contrast-rgb: 255, 255, 255;
    --ion-color-warning-dark-shade: #b07b14;
    --ion-color-warning-dark-tint: #ce982e;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #4d4d4d;
    --ion-color-dark-rgb: 77, 77, 77;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #444444;
    --ion-color-dark-tint: #5f5f5f;

    /** med-dark **/
    --ion-color-med-dark: #696969;
    --ion-color-med-dark-rgb: 105, 105, 105;
    --ion-color-med-dark-contrast: #ffffff;
    --ion-color-med-dark-contrast-rgb: 255, 255, 255;
    --ion-color-med-dark-shade: #5c5c5c;
    --ion-color-med-dark-tint: #787878;

    /** medium **/
    --ion-color-medium: #999999;
    --ion-color-medium-rgb: 153, 153, 153;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #878787;
    --ion-color-medium-tint: #a3a3a3;

    /** light-med **/
    --ion-color-light-med: #dadada;
    --ion-color-light-med-rgb: 218, 218, 218;
    --ion-color-light-med-contrast: #4d4d4d;
    --ion-color-light-med-contrast-rgb: 77, 77, 77;
    --ion-color-light-med-shade: #c0c0c0;
    --ion-color-light-med-tint: #dedede;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #4d4d4d;
    --ion-color-light-contrast-rgb: 77, 77, 77;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** white **/
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #4d4d4d;
    --ion-color-white-contrast-rgb: 77, 77, 77;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    /** excel **/
    --ion-color-excel: #3db39e;
    --ion-color-excel-rgb: 61, 179, 158;
    --ion-color-excel-contrast: #ffffff;
    --ion-color-excel-contrast-rgb: 255, 255, 255;
    --ion-color-excel-shade: #369e8b;
    --ion-color-excel-tint: #50bba8;

    /** pdf **/
    --ion-color-pdf: #e2574c;
    --ion-color-pdf-rgb: 226, 87, 76;
    --ion-color-pdf-contrast: #ffffff;
    --ion-color-pdf-contrast-rgb: 255, 255, 255;
    --ion-color-pdf-shade: #c74d43;
    --ion-color-pdf-tint: #e5685e;

    /** complete **/
    --ion-color-complete: #2ecc71;
    --ion-color-complete-rgb: 46, 204, 113;
    --ion-color-complete-contrast: #262626;
    --ion-color-complete-contrast-rgb: 38, 38, 38;
    --ion-color-complete-shade: #0c5f46;
    --ion-color-complete-tint: #43d17f;

    /** incomplete **/
    --ion-color-incomplete: #f89406;
    --ion-color-incomplete-rgb: 248, 148, 6;
    --ion-color-incomplete-contrast: #262626;
    --ion-color-incomplete-contrast-rgb: 38, 38, 38;
    --ion-color-incomplete-shade: #c7672e;
    --ion-color-incomplete-tint: #f99f1f;

    /** purple **/
    --ion-color-purple: #b959c0;
    --ion-color-purple-rgb: 185, 89, 192;
    --ion-color-purple-contrast: #000000;
    --ion-color-purple-contrast-rgb: 0, 0, 0;
    --ion-color-purple-shade: #a34ea9;
    --ion-color-purple-tint: #c06ac6;

    /** f9 **/
    --ion-color-f9: #f9f9f9;
    --ion-color-f9-rgb: 249, 249, 249;
    --ion-color-f9-contrast: #262626;
    --ion-color-f9-contrast-rgb: 38, 38, 38;
    --ion-color-f9-shade: #dbdbdb;
    --ion-color-f9-tint: #fafafa;

    /** outer-content **/
    --ion-color-outer-content: #f3f4f8;
    --ion-color-outer-content-rgb: 243, 244, 248;
    --ion-color-outer-content-contrast: #262626;
    --ion-color-outer-content-contrast-rgb: 38, 38, 38;
    --ion-color-outer-content-shade: #d6d7da;
    --ion-color-outer-content-tint: #f4f5f9;

    /** admin-dark **/
    --ion-color-admin-dark: #3b3b3b;
    --ion-color-admin-dark-rgb: 59, 59, 59;
    --ion-color-admin-dark-contrast: #ffffff;
    --ion-color-admin-dark-contrast-rgb: 255, 255, 255;
    --ion-color-admin-dark-shade: #343434;
    --ion-color-admin-dark-tint: #4f4f4f;

    /** light-blue-bg **/
    --ion-color-light-blue-bg: #f5fafd;
    --ion-color-light-blue-bg-rgb: 245, 250, 253;
    --ion-color-light-blue-bg-contrast: #008cd9;
    --ion-color-light-blue-bg-contrast-rgb: 56, 128, 255;
    --ion-color-light-blue-bg-shade: #daf2fc;
    --ion-color-light-blue-bg-tint: #f6fbfd;

    /** mint **/
    --ion-color-mint: #8de8d0;
    --ion-color-mint-rgb: 141, 232, 208;
    --ion-color-mint-contrast: #262626;
    --ion-color-mint-contrast-rgb: 38, 38, 38;
    --ion-color-mint-shade: #7cccb7;
    --ion-color-mint-tint: #98ead5;

    /** pdf-badge **/
    --ion-color-pdf-badge: #fee2e2;
    --ion-color-pdf-badge-rgb: 254, 226, 226;
    --ion-color-pdf-badge-contrast: #c33c3c;
    --ion-color-pdf-badge-contrast-rgb: 226, 87, 76;
    --ion-color-pdf-badge-shade: #e0c7c7;
    --ion-color-pdf-badge-tint: #fee5e5;

    /** xlsx-badge **/
    --ion-color-xlsx-badge: #d1fae5;
    --ion-color-xlsx-badge-rgb: 209, 250, 229;
    --ion-color-xlsx-badge-contrast: #0c6148;
    --ion-color-xlsx-badge-contrast-rgb: 12, 97, 72;
    --ion-color-xlsx-badge-shade: #b8dcca;
    --ion-color-xlsx-badge-tint: #d6fbe8;

    /** priority-high **/
    --ion-color-priority-high: #e20520;
    --ion-color-priority-high-rgb: 226, 5, 32;
    --ion-color-priority-high-contrast: #ffffff;
    --ion-color-priority-high-contrast-rgb: 255, 255, 255;
    --ion-color-priority-high-shade: #c7041c;
    --ion-color-priority-high-tint: #fedee3;

    /** priority-medium **/
    --ion-color-priority-medium: #fd9000;
    --ion-color-priority-medium-rgb: 253, 144, 0;
    --ion-color-priority-medium-contrast: #000000;
    --ion-color-priority-medium-contrast-rgb: 0, 0, 0;
    --ion-color-priority-medium-shade: #df7f00;
    --ion-color-priority-medium-tint: #fff4e5;

    /** priority-low **/
    --ion-color-priority-low: #00a6fd;
    --ion-color-priority-low-rgb: 0, 166, 253;
    --ion-color-priority-low-contrast: #ffffff;
    --ion-color-priority-low-contrast-rgb: 255, 255, 255;
    --ion-color-priority-low-shade: #0092df;
    --ion-color-priority-low-tint: #1aaffd;

    /** external-role **/
    --ion-color-external-role: #fff3c2;
    --ion-color-external-role-rgb: 255, 243, 194;
    --ion-color-external-role-contrast: #9f3a00;
    --ion-color-external-role-contrast-rgb: 159, 58, 0;
    --ion-color-external-role-shade: #e0d6ab;
    --ion-color-external-role-tint: #fff4c8;

    /** internal-role **/
    --ion-color-internal-role: #daf1cd;
    --ion-color-internal-role-rgb: 218, 241, 205;
    --ion-color-internal-role-contrast: #116e00;
    --ion-color-internal-role-contrast-rgb: 17, 110, 0;
    --ion-color-internal-role-shade: #c0d4b4;
    --ion-color-internal-role-tint: #def2d2;

    /** light-blue **/
    --ion-color-light-blue: #e6f4fc;
    --ion-color-light-blue-rgb: 230, 244, 252;
    --ion-color-light-blue-contrast: #008cd9;
    --ion-color-light-blue-contrast-rgb: 56, 128, 255;
    --ion-color-light-blue-shade: #cad7de;
    --ion-color-light-blue-tint: #e9f5fc;
}
