ion-button {
    --border-radius: 999px !important;
    --box-shadow: none !important;
    text-transform: none;
    letter-spacing: 0;

    &.ios:not(.button-small):not(.button-large) {
        height: 38px;
    }

    @for $i from 28 through 40 {
        &.button-#{$i} {
            height: #{$i}px !important;
        }
    }

    &.button-28-28 {
        height: 28px !important;
        width: 28px !important;
    }
    &.button-32-32 {
        height: 32px !important;
        width: 32px !important;
    }
    &.button-34-34 {
        height: 34px !important;
        width: 34px !important;
    }
    &.button-44-44 {
        height: 44px !important;
        width: 44px !important;
    }
    &.button-padding-2 {
        --padding-start: 2px;
        --padding-end: 2px;
    }
    &.button-padding-4 {
        --padding-start: 4px;
        --padding-end: 4px;
    }
    &.button-padding-5 {
        --padding-start: 5px;
        --padding-end: 5px;
    }

    &.button-36-36 {
        height: 36px !important;
        width: 36px !important;
    }

    $buttonPaddingSizes: 0, 2, 3, 4, 5, 6, 7, 10, 20, 32, 46;
    @each $size in $buttonPaddingSizes {
        &.button-padding-#{$size} {
            --padding-start: #{$size}px;
            --padding-end: #{$size}px;
        }
    }

    &.button-padding-24:not(.ios) {
        --padding-start: 24px;
        --padding-end: 24px;
    }

    &.button-outlined-medium {
        --border-color: var(--ion-color-medium);
        --border-style: solid;
        --border-width: 1px;
    }

    &.light-shadow {
        --box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08) !important;
    }

    &.button-border-1 {
        --border-width: 1px !important;
        --border-style: solid !important;
    }

    &.button-border-2 {
        --border-width: 2px !important;
    }

    &.no-ripple {
        --ripple-color: transparent;
    }

    &.hover-border-solid:hover {
        --border-style: solid;
    }

    &.hover-text-underline:hover {
        ion-label {
            text-decoration: underline;
        }
    }
}

.md {
    ion-button {
        --border-width: 1px;
    }
}

ion-button.md.button-outline {
    --border-width: 1px;
}

.search-button {
    --padding-start: 18px;
    --padding-end: 18px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 12px;
    height: 38px;
    font-size: 15px;
}

.icon-stroke-16 {
    --ionicon-stroke-width: 16px;
}

.quick-action-button {
    --background: rgba(var(--ion-color-primary-rgb), 0.75);
    --background-activated: rgba(var(--ion-color-primary-rgb), 0.9);

    &.grey {
        --background: rgba(var(--ion-color-dark-rgb), 0.75);
        --background-activated: rgba(var(--ion-color-dark-rgb), 0.9);
    }
}
