// Alert classes
ion-alert {
    .alert-wrapper {
        border-radius: 14px;
        background: var(--ion-color-white);
        &.sc-ion-alert-md {
            .alert-head,
            .alert-message {
                text-align: left;
            }
            input.alert-input,
            textarea.alert-input {
                border-radius: 0;
            }
        }
        .alert-button-group {
            justify-content: flex-end;
            padding-bottom: 16px;
            padding-right: 16px;
            padding-left: 16px;

            .alert-button {
                border-radius: 999px;
                border: none;
                min-width: 80px;
                padding: 7px 10px;
                text-transform: none;
                .alert-button-inner {
                    justify-content: center;
                }
                &.sc-ion-alert-md:last-of-type {
                    color: var(--ion-color-white);
                    background: var(--ion-color-primary);
                    margin-right: 0;
                    margin-left: 6px;
                }
                &.sc-ion-alert-ios {
                    height: 40px;
                    &:last-of-type {
                        border: 1px solid var(--ion-color-primary);
                        margin-right: 0;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}
//Select alert
ion-alert.select-alert {
    .alert-checkbox-icon {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        border-width: 1px;
        left: 24px;
        .alert-checkbox-inner {
            height: 13px;
            left: 8px;
            top: 2px;
            border-width: 1px;
            border-top-width: 0;
            border-left-width: 0;
        }
    }
    .alert-checkbox-label {
        -webkit-padding-start: 44px;
        padding-inline-start: 44px;
    }
    .alert-button-group {
        &.sc-ion-alert-md {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }
        &.sc-ion-alert-ios {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            border-top: 1px solid var(--ion-color-light-med);
        }
    }
    .alert-radio-group {
        max-height: 316px;
    }
}

//Checkbox alert
ion-alert.checkbox-alert {
    --max-width: 330px;

    .alert-checkbox-group {
        border-top: 0;
        border-bottom: 0;
    }

    .alert-checkbox-label {
        padding-inline-start: 40px;
        -webkit-padding-start: 40px;
    }

    .alert-message {
        padding-bottom: 0;
    }
}
