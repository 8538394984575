// CUSTOM ELEMENT CLASSES
.line-break {
    width: 100%;
    height: 0.567px;
    background: var(--ion-color-light-med);
}
.line-break-medium {
    width: 100%;
    height: 0.567px;
    background: var(--ion-color-medium);
}

.cursor-pointer:hover {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.no-pointer-events {
    pointer-events: none;
}

.all-pointer-events {
    pointer-events: all;
}

.fullscreen {
    --width: 100% !important;
    --height: 100% !important;
}

.external-link {
    color: var(--ion-color-primary);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.text-selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.hover-underline:hover {
    text-decoration: underline;
}

.datepicker-item:not(.is-disabled) {
    &:hover {
        --background: rgba(var(--ion-color-dark-rgb), 0.04);
    }
    &:active {
        --background: rgba(var(--ion-color-dark-rgb), 0.1);
    }
    &.ios.standalone-datepicker {
        --min-height: 54px;
    }
}

.page-bottom-pad {
    height: calc(20px + var(--ion-safe-area-bottom, 0));
}

// HEIGHT
.height-auto {
    height: auto;
}
.height-max-content {
    height: max-content;
}
.max-height-100 {
    max-height: 100%;
}
.max-height-100px {
    max-height: 100px;
}
.height-100 {
    height: 100%;
}
.min-height-unset {
    --min-height: unset;
}
.min-height-0 {
    min-height: 0;
}
.height-130px {
    height: 130px;
}
.height-100px {
    height: 100px;
}
.height-90px {
    height: 90px;
}
.height-46px {
    height: 46px;
}
.height-36px {
    height: 36px;
}
.height-32px {
    height: 32px;
}
.height-28px {
    height: 28px;
}
.height-24px {
    height: 24px;
}
.height-22px {
    height: 22px;
}
// WIDTH
.width-100 {
    width: 100%;
}
.width-max-content {
    width: max-content;
}
.max-width-max-content {
    max-width: max-content;
}
.max-width-100 {
    max-width: 100%;
}
.max-width-50 {
    max-width: 50%;
}
.width-32px {
    width: 32px;
}
.width-2px {
    width: 2px;
}

$widthThresholds: 0, 10, 90, 160, 180, 200, 300, 400, 432;
@each $size in $widthThresholds {
    .max-width-#{$size}px {
        max-width: #{$size}px;
    }
}

@each $size in $widthThresholds {
    .min-width-#{$size}px {
        min-width: #{$size}px;
    }
}

// FONT
@for $i from 0 through 36 {
    .font-#{$i} {
        font-size: #{$i}px !important;
    }
}

.ios {
    .font-15-ios {
        font-size: 15px;
    }
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.white-space-normal {
    white-space: normal;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.white-space-nowrap {
    white-space: nowrap;
}

@for $i from 0 through 24 {
    .line-height-#{$i} {
        line-height: #{$i}px;
    }
}

.no-text-decoration {
    text-decoration: none;
}

.text-underline {
    text-decoration: underline;
}

.text-line-through {
    text-decoration: line-through;
}

.no-text-transform {
    text-transform: none;
}

// BORDERS
.border-bottom {
    border-bottom: 1px solid #c8c7cc;
}

.border-bottom-dotted {
    border-bottom: 1px dotted var(--ion-color-medium);
}

.border-bottom-light-med {
    border-bottom: 1px solid var(--ion-color-light-med);
}

.border-top {
    border-top: 1px solid #c8c7cc;
}

.border-top-light-med {
    border-top: 1px solid var(--ion-color-light-med);
}

.border-left {
    border-left: 1px solid #c8c7cc;
}

.border-right {
    border-right: 1px solid #c8c7cc;
}

.border-all {
    border: 1px solid var(--ion-color-light-med);
}

.border-dashed {
    border: 1px dashed var(--ion-color-light-med);
}

.border-medium {
    border: 1px solid var(--ion-color-medium);
}

.border-primary {
    border: 1px solid var(--ion-color-primary);
}

.border-width-2 {
    border-width: 2px;
}

$borderSizes: 0, 10, 14, 999;
@each $size in $borderSizes {
    .border-#{$size} {
        border-radius: #{$size}px;
    }
}

.no-border-radius {
    border-radius: 0 !important;
}

// MARGINS & PADDING
@for $i from 0 through 46 {
    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
    .m-#{$i} {
        margin: #{$i}px !important;
    }

    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }

    .padding-#{$i} {
        padding: #{$i}px !important;
    }
}

@for $i from 0 through 16 {
    .mt-neg-#{$i} {
        margin-top: -#{$i}px !important;
    }
    .mr-neg-#{$i} {
        margin-right: -#{$i}px !important;
    }
    .mb-neg-#{$i} {
        margin-bottom: -#{$i}px !important;
    }
    .ml-neg-#{$i} {
        margin-left: -#{$i}px !important;
    }
}

// OTHER MARGINS
.margin-auto {
    margin: auto;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-auto-horizontal {
    margin-right: auto;
    margin-left: auto;
}

.margin-auto-vertical {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.ios {
    .mt-14-ios {
        margin-top: 14px;
    }
}

// OTHER PADDING
.no-inner-padding {
    --inner-padding-end: 0;
}

.pb-safe {
    --padding-bottom: var(--ion-safe-area-bottom) !important;
}

.pr-66 {
    padding-right: 66px;
}

.pl-50 {
    padding-left: 50px !important;
}

// MEDIA BASED MARGIN & PADDING
@media (max-width: 575px) {
    .size-xs-padding {
        padding: 16px;
    }

    .size-xs-padding-right {
        padding-right: 16px;
    }

    .mt-0-mobile {
        margin-top: 0 !important;
    }
}
@media (min-width: 576px) {
    .mt-16-small {
        margin-top: 16px !important;
    }

    .size-sm-padding {
        padding: 16px;
    }

    .size-sm-padding-horizontal {
        padding-left: 16px;
        padding-right: 16px;
    }

    .size-sm-pb-32 {
        padding-bottom: 32px;
    }
}

// DISPLAY FLEX
.display-flex {
    display: flex;
}

.display-contents {
    display: contents;
}

.display-grid {
    display: grid;
}

.display-inline-flex {
    display: inline-flex;
}

.display-flex-column {
    display: flex;
    flex-direction: column;
}

.row-reverse {
    flex-direction: row-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}

.vertical-align-content {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
}

.vertical-align-end {
    display: flex !important;
    align-items: flex-end !important;
}

.inline-vertical-align {
    display: inline-flex !important;
    align-content: center !important;
    align-items: center !important;
}

.vertical-align-self {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flex-fill {
    flex-grow: 1000;
    display: inline-block;
}

$flexSizes: 0, 1, 2, 10;
@each $size in $flexSizes {
    .flex-grow-#{$size} {
        flex-grow: $size;
    }

    .flex-shrink-#{$size} {
        flex-shrink: $size;
    }
}

// DISPLAY & VISIBILITY
.display-none {
    display: none;
}

.display-block {
    display: block;
}

.display-table {
    display: table;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible !important;
}

.opacity-1 {
    opacity: 1 !important;
}
.opacity-60 {
    opacity: 0.6 !important;
}
.opacity-50 {
    opacity: 0.5 !important;
}
.opacity-40 {
    opacity: 0.4 !important;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-cover {
    object-fit: contain;
}

// POSITION
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.position-fixed {
    position: fixed;
}
.self-center {
    align-self: center;
}

$absoluteOffsetSizes: 0, 3, 4, 6, 10, 14, 16, 1000, 1001;
@each $size in $absoluteOffsetSizes {
    .top-#{$size} {
        position: absolute;
        top: #{$size}px;
    }

    .right-#{$size} {
        position: absolute;
        right: #{$size}px;
    }

    .bottom-#{$size} {
        position: absolute;
        bottom: #{$size}px;
    }

    .left-#{$size} {
        position: absolute;
        left: #{$size}px;
    }
}

// Z INDEX
$zIndexes: 0, 10, 800, 900, 999, 1000, 1001;
@each $index in $zIndexes {
    .z-#{$index} {
        z-index: $index !important;
    }
}

// OVERFLOW
.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

li.overflow-ellipsis {
    list-style-position: inside;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll-y {
    overflow-y: scroll;
}

.overflow-scroll-y-important {
    overflow-y: scroll !important;
}

.overflow-scroll-x {
    overflow-x: scroll;
}

.no-border-bleed {
    overflow: hidden;
    z-index: 100;
    position: relative;
}

.contain-none {
    contain: none;
}

.line-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-clamp-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

// COLOURS
.color-white {
    color: var(--ion-color-white) !important;
}
.color-medium {
    color: var(--ion-color-medium);
}
.color-med-dark {
    color: var(--ion-color-med-dark);
}
.color-medium-shade {
    color: var(--ion-color-medium-shade);
}
.color-auth-text {
    color: var(--ion-color-auth-text);
}
.color-primary-tint {
    color: var(--ion-color-primary-tint);
}
.color-primary {
    color: var(--ion-color-primary);
}
.color-success {
    color: var(--ion-color-success);
}
.color-admin-dark {
    color: var(--ion-color-admin-dark);
}
.color-dark {
    color: var(--ion-color-dark);
}
.color-dark-tint {
    color: var(--ion-color-dark-tint);
}
.color-danger {
    color: var(--ion-color-danger);
}
.hover-color-primary:hover {
    color: var(--ion-color-primar);
}

// BACKGROUNDS
.background-transparent {
    --background: transparent;
}

.background-primary {
    background: var(--ion-color-primary);
}

.background-secondary {
    background: var(--ion-color-secondary);
}

.background-white {
    background: var(--ion-color-white);
}

.background-ff {
    background: var(--ion-color-ff);
}

.background-medium {
    background: var(--ion-color-medium);
}

.background-light-med {
    background: var(--ion-color-light-med);
}

.background-file-viewer {
    background: #e8e8eb;
}

// LISTS
.list-style-none {
    list-style: none;
}
