app-datepicker {
    width: 100%;
}
.checkbox-date-time {
    ion-label {
        flex: 0 0 90px;
        min-width: 90px;
    }
}
.week-toolbar {
    padding-top: 0 !important;
}
.custom-label-float {
    transform: translateY(50%) scale(0.75);
}
