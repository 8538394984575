// For all components
lib-switch-plan,
app-avatar-dropdown,
app-manage-project-image {
    position: absolute;
    width: 100%;
    border-radius: 14px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    max-height: 0;
}

// Component specific
app-manage-project-image {
    top: 36px;
    white-space: nowrap;
    min-width: 140px;
}

lib-switch-plan {
    background: white;
}

lib-switch-plan,
app-avatar-dropdown {
    top: 51px;
    min-width: 200px;
    right: 0;
}

@media (min-width: 576px) {
    app-avatar-dropdown {
        top: 54px;
        z-index: 1000;
    }
}
