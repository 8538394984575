.help-widget {
    transition: background-color 0.25s;
    height: 30px;
    width: 80px;
    background-color: var(--ion-color-primary);
    position: absolute;
    right: -26px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 1000;
    display: table;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    &:hover {
        background-color: var(--ion-color-secondary);
    }

    ion-text {
        margin: auto;
        display: table;
        color: var(--ion-color-light);
    }
}
