.tippy-content {
    text-align: center;
    .message-container {
        padding: 5px 9px;
        margin: -5px -9px;
        cursor: pointer;
    }
}

.teams-row-header {
    [tippy],
    .tippy-help-icon {
        margin-bottom: 10px;
        margin-left: 4px;
        flex-shrink: 0;
        font-size: 18px;
    }
}

.pin-tippy {
    .tippy-box {
        color: #ffffff;
        background-color: #333333;
        border-radius: 8px;
        box-shadow: 1px 2px 6px 0 #aeaeb0;
    }

    .tippy-arrow {
        color: #333333;
    }
}
