.outer-content {
    --background: var(--ion-color-outer-content);
    --padding-bottom: var(--ion-safe-area-bottom);
    ion-list-header {
        --background: var(--ion-color-outer-content);
    }
}
.outer-content.no-outer-content-padding {
    --padding-bottom: 0;
}
.outer-content-background {
    background: var(--ion-color-outer-content);
}
.ios {
    .outer-content {
        ion-list {
            ion-item {
                --background: transparent;
            }
        }
    }
}
