// CARDS
ion-card,
ion-row.card-row {
    box-shadow: none;
    border-radius: 14px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-med);
}

.card-icon {
    min-width: 30px;
    margin-left: 22px;
    pointer-events: none;
}

.card-detail-arrow {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    min-width: 30px;
    font-size: 22px;
    pointer-events: none;
}
