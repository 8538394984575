ion-item {
    --background-focused-opacity: 0;
    --detail-icon-opacity: 0.65;
    --highlight-color-valid: var(--ion-color-primary);

    &.item-has-focus {
        --border-color: var(--ion-color-primary);

        ion-label {
            color: var(--ion-color-primary);
        }

        ion-input,
        textarea {
            color: var(--ion-color-dark);
        }
    }

    ion-select::part(icon) {
        opacity: 0.65;
    }

    ion-select.select-disabled::part(icon) {
        opacity: 0;
    }
}

.ios {
    ion-item.item-interactive.ion-valid.item-has-focus {
        --highlight-height: 0;
    }
}

.md {
    ion-item.item-lines-inset.item-has-focus {
        --highlight-height: 1px;
    }
}

ion-input,
ion-textarea {
    --color: var(--ion-color-dark);
}

ion-label {
    font-size: 15px !important;
    --color: var(--ion-color-dark) !important;
}

// textarea clear icon
[libTextAreaClearIcon] {
    margin-top: 0;
    padding-right: 30px;
    textarea::-webkit-scrollbar {
        display: none;
    }
}

ion-item {
    .textarea-clear-icon {
        display: none;
    }
    &.item-has-value:not(.fake-disabled) {
        .textarea-clear-icon {
            display: block;
        }
    }
}

.textarea-clear-icon {
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 1000;
    font-size: 20px;
    padding: 5px;
    color: rgba(0, 0, 0, 0.65);
}

// password peep icon
ion-item.item-has-focus,
ion-item.item-has-value {
    .password-peep-icon {
        color: var(--ion-color-dark);
    }
}

.password-peep-icon {
    z-index: 1000;
    font-size: 20px;
    padding: 5px;
    margin-right: 5px;
    color: var(--ion-color-medium);
    pointer-events: all;

    &:hover {
        color: var(--ion-color-dark);
    }
}

// selects
ion-item.item-select.select-maintain-option-width {
    ion-label {
        overflow: visible;
    }

    ion-select {
        max-width: unset;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

ion-item.item-select:not(.item-has-value) {
    ion-label.label-floating {
        padding-right: 20px;
    }
}

ion-select::part(text) {
    color: var(--ion-color-admin-dark);
}

// fake-disabled item
ion-item.fake-disabled {
    pointer-events: none;

    ion-checkbox:not(.disabled-opacity-1),
    ion-input:not(.disabled-opacity-1),
    ion-textarea:not(.disabled-opacity-1),
    ion-radio:not(.disabled-opacity-1) {
        opacity: 0.8;
    }
}

ion-textarea.disabled-opacity-1 {
    textarea {
        opacity: 1 !important;
    }
}

ion-input.white-icon-circle {
    .input-clear-icon.sc-ion-input-ios,
    .input-clear-icon.sc-ion-input-md {
        background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='rgb%28255,255,255%29'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>") !important;
    }
}

ion-input.white-icon {
    .input-clear-icon.sc-ion-input-ios,
    .input-clear-icon.sc-ion-input-md {
        background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2024%2024'><path%20fill='rgb%28255,255,255%29'%20d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/></svg>") !important;
        background-size: 14px;
    }
}

// rounded-item
.rounded-item {
    --background: transparent;
    --inner-border-width: 0;
    --inner-padding-end: 0;
    --border-color: transparent !important;
    --highlight-height: 0px;
    --highlight-color-valid: transparent !important;
    font-size: 15px;

    &.fake-disabled {
        pointer-events: none;
    }

    &.ion-invalid.highlight-invalid.ion-touched {
        ion-input,
        textarea,
        ion-textarea {
            &:not(.has-focus) {
                border-color: var(--ion-color-danger) !important;
            }
        }
    }

    ion-input {
        --padding-start: 12px !important;
        margin-top: 3px;
        border-radius: 999px;
        overflow: hidden;

        button.input-clear-icon {
            margin-right: 6px;
        }

        &.autofocus-light-blue {
            input.native-input.sc-ion-input-md:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px #bee8ff inset;
            }
        }
    }

    ion-textarea {
        border-radius: 14px;
        margin-top: 2px;
        --padding-start: 12px;
        --padding-bottom: 8px;
        --padding-top: 8px;

        &.border-full {
            border-radius: 19px;
        }
    }

    ion-label {
        margin-bottom: 8px;
        margin-left: 12px;
        font-size: 16px;
    }

    &.light,
    &.white,
    &.dark,
    &.secondary {
        ion-input,
        ion-textarea {
            --placeholder-opacity: 0.8;
        }

        ion-input {
            min-height: 38px;
        }

        &.item-has-focus,
        &.ion-invalid.highlight-invalid {
            ion-input,
            textarea,
            ion-textarea {
                border-width: 2px;
                --padding-start: 11px !important;
                --placeholder-opacity: 0.5;

                .textarea-clear-icon {
                    padding-right: 4px;
                }
            }

            ion-input {
                --padding-start: 11px !important;
                --height: 34px;
                height: 38px;

                .input-clear-icon {
                    margin-right: 5px;
                }

                .password-peep-icon {
                    margin-right: 4px;
                }
            }

            ion-textarea {
                margin-top: 1px;
            }

            &.textarea-container {
                margin-bottom: -1px;
            }
        }
    }

    &.white {
        &.item-has-focus {
            ion-input,
            textarea,
            ion-textarea {
                color: var(--ion-color-dark);
                border-color: var(--ion-color-primary) !important;
            }
        }

        ion-input,
        ion-textarea {
            border: 1px solid var(--ion-color-light-med);
        }

        ion-input {
            --background: var(--ion-color-white);
        }

        ion-textarea {
            background: var(--ion-color-white);
        }
    }

    &.light {
        &.item-has-focus {
            ion-input,
            textarea,
            ion-textarea {
                color: var(--ion-color-dark);
                border-color: var(--ion-color-primary) !important;
            }
        }

        &.fake-disabled {
            ion-input,
            ion-textarea {
                border-color: var(--ion-color-auth-secondary) !important;
            }

            &.no-border {
                ion-input,
                ion-textarea {
                    border-color: #f7f7f7;
                }
            }
        }

        ion-input,
        ion-textarea {
            border: 1px solid #dcdcdc;
        }

        ion-input {
            --background: #f4f4f4;
        }

        ion-textarea {
            background: #f4f4f4;
        }

        &.no-border {
            ion-input,
            ion-textarea {
                border-color: #f4f4f4;
            }
        }
    }

    &.dark {
        &.item-has-focus {
            ion-input,
            textarea,
            ion-textarea {
                color: var(--ion-color-dark);
                border-color: var(--ion-color-primary) !important;
            }
        }

        ion-input,
        ion-textarea {
            border: 1px solid var(--ion-color-medium);
        }

        ion-input {
            --background: #f4f4f4;
        }

        ion-textarea {
            --background: #f4f4f4;
        }
    }

    &.secondary {
        &.item-has-focus {
            ion-input,
            textarea,
            ion-textarea {
                color: var(--ion-color-white);
                border-color: rgba(255, 255, 255, 0.8) !important;
            }

            ion-label {
                color: var(--ion-color-white);
            }
        }

        ion-input,
        ion-textarea {
            border: 1px solid var(--ion-color-secondary);
            --color: white;
            caret-color: #ffffff;
            --placeholder-color: var(--ion-color-white);
        }

        ion-input {
            --background: var(--ion-color-secondary);
        }

        ion-textarea {
            --background: var(--ion-color-secondary);
        }
    }

    // No label
    &.round-no-label {
        overflow: visible;
        height: 38px;

        ion-input {
            margin-top: -7px;
            height: 36px !important;
        }
    }

    &.round-no-label.md {
        ion-input {
            margin-bottom: 6px;
        }
    }
}

.md .rounded-item {
    ion-input {
        input {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

// Rounded list item
ion-item.rounded-list-item {
    height: 48px;

    &.item-has-focus {
        --border-color: var(--ion-color-primary);
        border: 2px solid var(--ion-color-primary);
        border-radius: 999px;
        --highlight-height: 0px;
        --highlight-color-valid: transparent !important;
        --padding-start: 15px;

        ion-label {
            margin-top: 10px;
        }

        ion-input {
            margin-top: -1px;

            .input-clear-icon {
                margin-top: -1px;
                margin-right: -1px;
            }
        }
    }
}

// checkbox-item
ion-item.checkbox-item {
    --padding-start: 4px;
    --min-height: 36px;
    --border-style: none;
    --ripple-color: transparent;
    border-radius: 999px;

    ion-checkbox {
        margin: 9px 8px 9px 6px;
    }

    ion-label {
        margin: 4px 0 6px 6px;
    }

    span.link-checkbox-container {
        position: relative;
    }

    .span-link {
        text-decoration: underline;
    }

    &.ios {
        .checkbox-container {
            ion-checkbox {
                margin-left: 0;
                --border-color: var(--ion-color-auth-text);
                --border-width: 1px;
            }
        }
    }
}

// Input number type
.numeric-input-container {
    display: flex;
    align-content: center;
    align-items: center;

    ion-label {
        position: absolute;
        left: 16px;
    }

    ion-item {
        flex-grow: 1;
        --padding-start: 80px;

        ion-input {
            input.native-input.sc-ion-input-md::-webkit-inner-spin-button {
                opacity: 0;
            }
        }
    }

    &:not(:last-child) {
        padding-left: 16px;
    }
}

.ios {
    .numeric-input-container {
        ion-label {
            left: 20px;
        }

        ion-item {
            --padding-start: 0;

            input {
                padding-right: 10px;
            }
        }

        &:not(:last-child) {
            padding-left: 20px;
        }
    }
}
