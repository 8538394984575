ion-card,
ion-row.card-row,
ion-item,
ion-list,
div {
    &.clickable {
        --background: none !important;
        background: var(--ion-color-white);
        &:active:not(.disabled) {
            transform: scale(0.995);
            background: var(--ion-color-light-blue-bg);
            border: 1px solid var(--ion-color-primary);
        }
        @media (any-hover: hover) and (pointer: fine) {
            &:hover:not(.disabled) {
                border: 1px solid var(--ion-color-primary);
                cursor: pointer;
            }
        }
    }
    &.is-shaded-blue {
        background: var(--ion-color-light-blue-bg-shade);
    }
    @media (any-hover: hover) and (pointer: fine) {
        &.hoverable:hover {
            border: 1px solid var(--ion-color-primary);
        }
    }
}

ion-item.clickable {
    border-radius: 14px;
    --border-style: none;
    --inner-border-width: 0;
}
