.show-tablet,
.show-tablet-block,
.show-tablet-flex,
.show-desktop {
    display: none;
}

@media (max-width: 575px) {
    .hide-mobile {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hide-mobile-tablet {
        display: none !important;
    }
}
//Small
@media (min-width: 576px) {
    .hide-small {
        display: none !important;
    }
}

//Tablet
@media (min-width: 768px) {
    .show-tablet {
        display: unset;
    }
    .show-tablet-block {
        display: block;
    }
    .show-tablet-flex {
        display: flex;
    }
    .hide-tablet {
        display: none !important;
    }
}

//Desktop
@media (min-width: 992px) {
    .hide-desktop {
        display: none !important;
    }
    .show-desktop {
        display: unset;
    }
}
