.tag {
    border-radius: 999px;
    padding: 4px 12px;
    margin: 0 4px 4px 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
}

.tag-indicator {
    height: 24px;
    width: 24px;
    border-radius: 999px;
}
