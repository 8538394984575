.placeholder {
    text-align: center;
    img.placeholder-img {
        max-height: 24vh;
        width: 60%;
        max-width: 170px;
        margin-top: 20px;
        min-height: 90px;
    }
    p {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        max-width: 660px;
    }
}

@media (min-width: 992px) {
    .placeholder {
        margin-top: 16px;
    }
}
