ion-list,
ion-reorder-group {
    --background: transparent;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;

    form {
        border-radius: 14px;
        border: none;
    }
}

ion-list,
ion-reorder-group {
    ion-item:last-child:not(.maintain-border) {
        --border-style: none;
        --border-color: transparent;
    }
}

ion-list {
    margin: 0 16px 16px;
}

ion-reorder-group {
    margin: 0;
}

ion-list.clickable-item-list,
ion-reorder-group.clickable-item-list {
    ion-item {
        &:first-child {
            z-index: 10;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
        }

        &:last-child {
            z-index: 10;
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
        }
    }
}

ion-list.clickable-sliding-item-list ion-item-sliding {
    &:first-child {
        ion-item {
            z-index: 10;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
        }
    }

    &:last-child {
        ion-item {
            --border-style: none;
            z-index: 10;
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
        }
    }
}

ion-item-sliding {
    ion-item-options {
        border: none;
    }
}

ion-list-header {
    --color: var(--ion-color-dark);
    padding-left: 24px;
    padding-bottom: 6px;
    text-transform: uppercase;
}

.list-md {
    padding: 0;
    margin: 0 16px 16px;
}

.list-description {
    padding: 0 16px;
    font-size: 14px;
    margin-left: 16px;
}

.list-description-header {
    padding: 0 28px;
    font-size: 14px;
}

.hidden-delete-button {
    display: none;
}

ion-item.hidden-delete-item {
    --inner-padding-end: 6px;

    &:hover,
    &.item-has-focus {
        .hidden-delete-button {
            display: block;
        }
    }
}

ion-item::part(detail-icon),
ion-item ion-icon[slot='start'] {
    pointer-events: none;
}

// VIRTUAL SCROLL
app-grouped-list {
    width: 100%;
    height: 100%;
}

virtual-scroller {
    width: 100%;
    height: 100%;
}
